<template>
  <div class="page">
    <Login ref="login" :callback="init" mode="GST" />
    <Navbar title="公招报名" type="SMP" />
    <div class="top">
      <div class="title">报名服务</div>
      <div class="name">{{ inform.name }}</div>
      <div class="count">招聘人数：{{ inform.count }}人</div>
      <div class="time">
        报名时间：{{ inform.startTime }} 到 {{ inform.endTime }}
      </div>
    </div>
    <div class="explain">
      <div class="title">报名须知</div>
      <div class="item">1、服务包含简历制作、资料整理、资料投递等服务</div>
      <div class="item">2、提交报名后需按照招聘要求提交个人资料</div>
    </div>
    <div class="amount">
      <div class="title">服务费用</div>
      <div class="item">
        <div class="left">剩余次数</div>
        <div class="right color-s2">{{ applyCount }}次</div>
      </div>
      <div class="button">
        <van-button
          type="primary"
          :color="COLOR_M"
          class="btn"
          @click="createOrder"
          icon="certificate"
          size="small"
          block
          >立即报名</van-button
        >
      </div>
    </div>
    <Loading :show="loadingShow" />
    <Share ref="share" />
    <LogVisit
      ref="logVisit"
      module-type="EDU"
      module-label="EDU_SIGNUP_INDEX"
    />
  </div>
</template>
<script>
import Index from './Index.js'
export default {
  ...Index
}
</script>
<style lang="less" scoped>
@import './Index.less';
</style>
